import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  loadUsersList: ['page', 'pageSize', 'sort', 'filter'],
  patchUser: ['id', 'data'],
  sendConfirmEmail: ['id'],
  runReVote: ['user_id', 'last_days'],

  updateCount: ['count'],
  updateUsersList: ['usersList'],
  updateFetching: ['fetching'],
})

export const UsersTypes = Types
export default Creators

/* ------------- Initial State ------------- */

type UsersState = {
  count: number,
  usersList: any,
  fetching: boolean,
}

export const INITIAL_STATE = Immutable({
  count: 0,
  usersList: [],
  fetching: false,
})

/* ------------- Reducers ------------- */

export const updateCount = (state: any, { count }: any) =>
  state.merge({ count })

export const updateUsersList = (state: any, { usersList }: any) =>
  state.merge({ usersList })

export const updateFetching = (state: any, { fetching }: UsersState) =>
  state.merge({ fetching })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_COUNT]: updateCount,
  [Types.UPDATE_USERS_LIST]: updateUsersList,
  [Types.UPDATE_FETCHING]: updateFetching,
})
