import { call, put } from 'redux-saga/effects'
import UsersActions from '../redux/usersRedux'
import MessageActions from '../redux/messageRedux'
import API from '../services/api'
import { buildUrls } from '../utils/functions'

export function* loadUsersList(action: any) {
  try {
    yield put(UsersActions.updateFetching(true))

    // @ts-ignore
    const response = yield call(API.get, buildUrls('users/', action))

    if (response.ok) {
      yield put(UsersActions.updateCount(response.data.count))
      yield put(UsersActions.updateUsersList(response.data.results))
    } else {
      yield put(UsersActions.updateCount(response.data.count))
      yield put(UsersActions.updateUsersList([]))
      throw new Error(response.data.detail ?? response.data)
    }
  } catch (e) {
    console.error("Error on load users list", e)
    const result = (e as Error).message;
    yield call(onError, result)
  }

  yield put(UsersActions.updateFetching(false))
}

export function* patchUser(action: any) {
  try {
    yield put(UsersActions.updateFetching(true))

    // @ts-ignore
    const response = yield call(API.patch, `users/${action.id}/`, action.data)

    if (response.ok) {
      yield put(MessageActions.openSnackbarWithColor("Successfully saved!", "success"))
    } else {
      const messages = Object.keys(response.data).map(key => `${key}: ${response.data[key]}`)
      throw new Error(messages.join('</br>'))
    }
  } catch (e) {
    console.error("Error on patch user", e)
    const result = (e as Error).message;
    yield call(onError, result)
  }

  yield put(UsersActions.updateFetching(false))
}

export function* sendConfirmEmail(action: any) {
  try {
    yield put(UsersActions.updateFetching(true))

    // @ts-ignore
    const response = yield call(API.post, `users/${action.id}/send_confirm_email/`)

    if (response.ok) {
      yield put(MessageActions.openSnackbarWithColor(response.data.message, "success"))
    } else {
      yield call(onError, response.data.message)
    }
  } catch (e) {
    console.error("Error on send confirm email to user", e)
    const result = (e as Error).message;
    yield call(onError, result)
  }

  yield put(UsersActions.updateFetching(false))
}

export function* runReVote(action: any) {
  try {
    yield put(UsersActions.updateFetching(true))

    // @ts-ignore
    const response = yield call(API.post, `users/${action.user_id}/run_re_vote/`, {
      last_days: action.last_days,
    })

    if (response.ok) {
      yield put(MessageActions.openSnackbarWithColor(response.data.message, "success"))
    } else {
      yield call(onError, response.data.message)
    }
  } catch (e) {
    console.error("Error on run re vote for user", e)
    const result = (e as Error).message;
    yield call(onError, result)
  }

  yield put(UsersActions.updateFetching(false))
}

function* onError(message = "Oops.. Something went wrong!") {
  yield put(MessageActions.openSnackbarWithColor(message, "error"))
  yield put(UsersActions.updateFetching(false))
}
